
.course-map {
  width: 1920px;
  height: 1080px;
  transition: transform .5s ease;
}

.course-map .text-problem-task__container {
  height: 100%;
}


.course-map__modal {
  /*position: absolute;*/
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  transition: opacity .8s ease, z-index 1s;
}

.course-map__modal .rangeNum {
  font-size: 16px;
}
.course-map__modal .rangeNum--lock path {
  /*fill: #a8071a;*/
}
.course-map__modal .rangeNum--unlock path {
  fill: #7cb305;
}

.course-map__task-success-modal {
  max-width: 450px;
  width: 100%;
  height: 400px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  background-color: white;
  border-radius: 20px;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 2px 6px rgba(0,0,0,0.5);
}
.course-map__task-success-modal h1 {
  font-size: 38px;
  font-family: 'Roboto-Bold';
  margin: 0;
  color: #49496D;
  text-align: center;
}
.course-map__task-success-modal button {
  height: auto;
  border-radius: 15px;
  color: white;
  background-color: #FCBD36;
  border-bottom: 9px solid #FCA736;
  width: 100%;
  margin-top: 15px;
}
.course-map__task-success-modal button span {
  font-size: 32px;
  font-family: 'Roboto-Medium';
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .course-map__task-success-modal {
    max-width: 300px;
    height: 250px;
    padding: 20px 30px;
  }
  .course-map__task-success-modal h1 {
    font-size: 23px;
  }
  .course-map__task-success-modal img {
    width: 70px;
  }
  .course-map__task-success-modal button span {
    font-size: 23px;
  }
}

.course-map__change-lang-widget {
  position: fixed;
  top: 0;
  left: 60px;
  width: 58px;
  z-index: 10;
}

.course-map__statistic-btn {
  position: fixed;
  top: 30px;
  right: 30px;
  border-radius: 10px;
  z-index: 10;
}
.course-map__statistic-btn span {
  font-family: Roboto;
}

.course-map__success-screen {
  width: 90%;
  height: 90%;
  border-radius: 30px;
  overflow: hidden;
  right: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0 10px 16px 6px rgba(0, 0, 0, .3);
}
@media screen and (max-width: 1000px) {
  .course-map__success-screen {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
.course-map__success-screen .success-screen {
  height: 100%;
}
.course-map__modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 40px;
  cursor: pointer;
  transition: opacity .8s ease;
  /*color: white;*/
  z-index: 100;
}
.course-map__modal-close img {
  width: 1.5em;
  height: 1.5em;
}
