

.layout2__menu-backdrop {
  position: absolute;
  background: rgba(255,255,255, .5);
  width: 100%;
  height: 100%;
  z-index: 100;
}
.layout2__menuWrapper.visible {
  left: 0;
  opacity: 1;
}
.layout2__menuWrapper.hidden {
  left: -500px;
  opacity: 0;
  flex: 1;
}
.layout2__menuWrapper {
  flex-shrink: 0;
  max-width: 280px;
  min-width: 150px;
  height: 100%;
  position: sticky;
  top: 80px;
  width: 100%;
  justify-self: flex-start;
  flex-direction: column;
  transition: opacity .8s ease-in-out, left .8s ease-in-out;
}

.layout2__menu {
  overflow-y: auto;
  height: 92vh;
  padding: 55px 0 10px;
  width: 100%;
  max-width: 280px;
  min-width: 150px;
  background-color: #F0F0F0;
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.1);
}


.side-bar__menu-item {
  color: rgba(85, 91, 97, 1);
  font-size: 16px;
  padding: 0 10px 0 27px;
  cursor: pointer;
  line-height: 1.3;
  margin: 0;
  min-height: 60px;
  display: flex;
  align-items: center;
}
.side-bar__menu-item:hover {
  background-color: rgba(255,255,255, .6);
}
.side-bar__menu-item:hover div,
.side-bar__menu-item:hover h3 {
  color: #2C79F6;
}
.side-bar__menu-item .side-bar__grade-title {
  font-family: 'Roboto';
  color: rgba(85, 91, 97, 1);
  font-size: 21px;
  padding: 10px 0;
}
.side-bar__menu-item .side-bar__chapter-title {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
}
.side-bar__menu-item .side-bar__paragraph-title {
  font-family: 'Roboto';
  color: rgba(85, 91, 97, 1);
  font-size: 15px;
  padding: 10px 0;
}
.side-bar__menu-item .side-bar__show-all-title {
  color: rgba(85, 91, 97, 1);
  font-size: 21px;
  margin: 0;
}

.side-bar__menu-item.side-bar__show-all--selected,
.side-bar__menu-item.side-bar__grade--selected,
.side-bar__menu-item.side-bar__chapter--selected {
  background: white;
}
.side-bar__menu-item.side-bar__paragraph--selected {
  background: #EEF3FE;
  position: relative;
}
.side-bar__menu-item.side-bar__paragraph--selected::before {
  content: '';
  border-left: 8px solid #2C79F6;
  position: absolute;
  left: 0; top: 0;
  height: 100%;
}


@media screen and (max-width: 1200px) {
  .layout2__menuWrapper {
    min-width: auto;
    position: fixed;
    z-index: 102;
    left: 0;
    top: 86px;
  }
  .layout2__menu {
    padding: 150px 0 20px;
    height: 100vh;
  }
}
@media screen and (max-width: 992px) {
  .layout2__menuWrapper {
    min-width: auto;
  }
}

/* ==== Mobile menu ====  */

.layout2__menuWrapper .sidebar-mobile-menu {
  display: none;
}
.layout2__menuWrapper .sidebar-mobile-menu .header-menu__item {
  padding: 7px 0;
}
.layout2__menuWrapper .sidebar-mobile-menu .header-menu__item--active {
  background: rgba(0,0,0,0.1);
}

@media screen and (max-width: 1100px) {
  .layout2__menuWrapper .sidebar-mobile-menu {
    display: block;
  }
  .layout2__menuWrapper .sidebar-mobile-menu .header-menu {
    margin: 20px 0 30px;
  }
}

