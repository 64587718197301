
.school-task-drop-column {
  margin: 0 1.4em;
  width: 32em;
  position: relative;
}
.school-task-drop-column--enter .school-task-drop-column__drop-place {
  background-color: rgba(0,255,0,.2);
}
.school-task-drop-column--enter .school-task-drop-column__head {
  border-color: cornflowerblue;
}

.school-task-drop-column__head {
  background: rgba(255, 255, 255, 0.89);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.4em;
  position: relative;
  z-index: 10;
  border: 0.3em solid rgba(0,0,0,0.2);
  transition: .2s ease-in-out border-color;
  min-height: 75px;
}
.school-task-drop-column__title {
  color: #5365A7;
  font-size: 2.8em;
  font-family: 'Roboto-Bold';
  white-space: nowrap;
  text-align: center;
  line-height: 1.2;
}
.school-task-drop-column__body {
  padding: 1.4em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.school-task-drop-column__drop-place {
  position: absolute;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 5em;
  border-top-left-radius: 5em;
  border-bottom-right-radius: 2.1em;
  border-bottom-left-radius: 2.1em;
  display: none;
  transition: .3s ease-in-out background-color;
}
.school-task-drop-column__drop-place--visible {
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
}


@media screen and (max-width: 1600px) {

}
/*@media screen and (max-width: 1600px) {*/
/*  .school-task-drop-column {*/
/*    width: 400px;*/
/*  }*/
/*  .school-task-drop-column__title {*/
/*    font-size: 35px;*/
/*  }*/
/*}*/
