.valuesList > li {
   margin-bottom: 15px;
   display: flex;
   flex-direction: row;
   font-weight: 500;
}
.valuesList > li > img {
   position: relative;
   height: 100%;
   margin-right: 10px;
}
