
.choose-item-task {

}
.choose-item-task__btn-continue {
  margin: auto!important;
  width: 30em;
}
.choose-items-task__item {
  margin: 10px;
  cursor: pointer;
  border: 3px solid transparent;
  /*font-size: .8em;*/
  width: 20em;
  height: 20em;
  min-height: 20em;
}
.choose-item-task__item:hover {

}
.choose-item-task__item--hover:hover {
  border-color: #3E5193;
}

.choose-items-task__items {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50em;
  max-width: calc(25em * 4);
  width: 100%;
  margin: 2em auto auto;
  flex-wrap: wrap;
}

@media screen and (max-width: 450px) {
  .choose-items-task__items {
    max-width: calc(30em * 4);
  }
}
