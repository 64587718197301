
.news-section-container {
  padding-inline: 20px;
}

.news-section-container__info-icon {
  font-size: 17px;
  font-family: 'Roboto-Bold';
  color: white;
  margin: 0 10px 12px;
}
.news-section-container__title {
  font-size: 36px;
  font-family: 'Roboto-Bold';
}
.news-section-container--dark-mode .news-section-container__title {
  color: white;
}
.news-section-container__head {
  display: flex;
  align-items: center;
}
