
.login__gray-text {
  color: rgba(148, 148, 148, 1);
}
.login__forgot-password {
  display: flex;
  align-items: center;
}
.login__forgot-password-txt {
  margin-top: 0;
  font-size: 13px;
  line-height: 1;
  white-space: nowrap;
}
.login__forgot-password-btn {
  font-size: 13px;
}
.login__forgot-password-btn span {
  text-decoration: underline;
}
.login__forgot-password-btn span:hover {
  text-decoration: none;
}

.login__sing_up-btn {
  margin: 10px 0;
  color: rgba(64, 145, 247, 1);
  border-color: rgba(64, 145, 247, 1);
  opacity: 1;
}
.login__sing_up-btn:hover {
  opacity: .8;
}