

.modal-common .ant-card-head-title {
  display: none;
}
.modal-common .ant-card-head {
  border: none;
}
.modal-common .ant-modal-content {
  border-radius: 10px;
  background-color: #F0F2F5;
}
.modal-common .big-white-cross .ant-modal-close-x {
  color: white;
  font-size: 30px;
}

.modal-common .ant-modal-body {
  padding: 24px 24px 80px;
}
.modal-common .ant-modal-close-x {
  font-size: 25px!important;
}
.modal-common .head-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 280px;
  width: 100%;
  margin: 30px auto 20px;
}
.modal-body {}


.popup-for-anonymous .head-form img {
  margin-bottom: 20px;
}
.popup-for-anonymous .head-form p {
  font-size: 16px;
  color: #6B6B6B;
}
.popup-for-anonymous .ant-modal-body>div:not(.head-form) {
  align-items: flex-start!important;
  height: auto!important;
}
.popup-for-anonymous .ant-modal-body .ant-card.ant-card-bordered {
  margin-top: 0!important;
}


@media screen and (max-width: 800px) {
  .popup-for-anonymous .ant-modal-body {
    padding: 20px 20px 50px;
  }
}
@media screen and (max-width: 400px) {
  .popup-for-anonymous .head-form img {
    margin-bottom: 10px;
  }
  .popup-for-anonymous .ant-modal-body {
    padding: 10px 20px;
  }
  .popup-for-anonymous .login__forgot-password {
    margin-top: 20px;
    flex-direction: column;
  }
}


.popup-for-homework-copy-link .head-form img {
  margin-bottom: 35px;
}

.popup-for-homework-copy-link .step-item {
  margin-bottom: 40px;
}
.popup-for-homework-copy-link .step-item__title {
  font-size: 15px;
  color: dimgray;
}
.popup-for-homework-copy-link .step-item__title span {
  color: #2C79F6;
  font-weight: bold;
  margin-right: 5px;
}
.popup-for-homework-copy-link .step-item__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0;
}

.popup-for-homework-copy-link .step-item__content .link {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.popup-for-homework-copy-link .step-item__content button {
  background-color: #2C79F6;
  height: 50px!important;
  font-size: 15px;
  font-weight: bold;
}




.popup-for-congratulation-for-teacher .ant-modal-body {
  padding: 24px 24px 50px;
}
.popup-for-congratulation-for-teacher .head-form {
  max-width: 350px;
}
.popup-for-congratulation-for-teacher .head-form img {
  margin-bottom: 25px;
}
.popup-for-congratulation-for-teacher .head-form p,
.popup-for-congratulation-for-teacher .head-form h3 {
  text-align: center;
}

.popup-for-congratulation-for-teacher .head-form h3 {
  color: rgba(44, 121, 246, 1);
  font-size: 20px;
  margin-bottom: 50px;
  font-weight: bold;
}
.popup-for-congratulation-for-teacher .head-form p {
  font-size: 15px;
  color: #6B6B6B;
}
