
.homework-achievement {
  margin: 1% 0 10%;
}
.homework-achievement__card {
  background: #FFFFFF;
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 50px 15px 0;
  display: inline-block;

}
.homework-achievement__item {}

.hw-achievement-item {
  display: flex;
  align-items: center;
}
.hw-achievement-item__image {
  height: 140px;
  width: 140px;
}
.hw-achievement-item__username {
  font-size: 20px;
}

@media (max-width: 800px) {
  .hw-achievement-item__username {
    font-size: 17px;
  }
}
