.cat-map-background {
  background: url("../images/cat-map-bg.png") no-repeat center;
  background-size: contain;
  background-color: black;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}




.cat-map-item {
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  transition: transform .3s ease-in-out;
  transform: scale(1);
}
.cat-map-item:not([aria-disabled='true']) {
  cursor: pointer;
}
.cat-map-item:not([aria-disabled='true']):hover {
  transform: scale(1.2);
  z-index: 100;
}
