.success-screen {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: all .5s ease;
}

.success-screen__bg {
  width: 400vw;
  height: 400vh;
  position: absolute;
  background: url('../images/bg2.png') no-repeat 50% 50%;
  background-size: contain;
  animation: bg 90s linear infinite;
}
@keyframes bg {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.success-screen__buttons button {
  margin: 0 20px;
  border-radius: 5px;
}

.success-screen__content {
  position: relative;
  flex-direction: row;
  max-width: 1000px;
  /*padding: 0 40px;*/
  padding: 0 2em;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.tempLevel {
  position: absolute;
  top: -100px;
  margin: auto;
  /*max-width: 300px;*/
  max-width: 22em;
  animation: tmpImg 1.6s ease-in-out infinite;
}
.tempLevel__img {
  width: 100%;
}
@keyframes tmpImg {
  0% { transform: translateY(0px); }
  25% { transform: translateY(30px); }
  50% { transform: translateY(0px); }
  75% { transform: translateY(30px); }
  100% { transform: translateY(0px); }
}


@media screen and (max-width: 800px) {
  .success-screen {
    font-size: .8em;
  }
}

@media screen and (max-width: 600px) {
  .success-screen {
    font-size: .6em;
  }
}

@media screen and (max-width: 400px) {
  .success-screen {
    font-size: .4em;
  }
}
