

.form-container {
  padding: 30px;

  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container form {
  padding: 10px 15px 20px;
  background: white;
}

.form-container form label {
  margin-bottom: 14px;
  display: block;
  width: 250px;
}