
.level-achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.level-achievement__img {
  /*max-width: 250px;*/
  max-width: 18em;
  /*margin-bottom: 20px;*/
  margin-bottom: 1.6em;
}
.level-achievement__info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.level-achievement__info p {
  border-radius: 50px;
  /*padding: 10px 25px;*/
  padding: 0.5em 1.6em;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  /*font-size: 20px;*/
  font-size: 1.5em;
  color: white;
}
