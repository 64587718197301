

.statistic-filter {
  margin: 20px 0;
  display: flex;
  align-items: flex-start;
}
.statistic-filter__title {
  margin-right: 20px;
  vertical-align: middle;
  line-height: 42px;
  font-size: 16px;
}

.statistic-filter .select-group {
  display: flex;
  align-items: center;
}

.radio-group {}
.checkbox-group {}

.select-group .select-help-btn,
.select-group .select-btn,
.radio-group .radio-btn,
.checkbox-group .checkbox-btn {
  margin: 7px 10px 2px 0;
  border-radius: 30px!important;
  padding: 0 15px;
  border: 1px solid #2C79F6;
  min-height: 27px;
  height: auto;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.25);
  color: #2C79F6;
}


.radio-group .radio-btn input,
.checkbox-group .checkbox-btn .ant-checkbox,
.radio-group .radio-btn:before,
.checkbox-group .checkbox-btn:before {
  display: none;
}

.checkbox-group .checkbox-btn span {
  padding: 0;
}

.select-group .select-btn--selected,
.radio-group .ant-radio-button-wrapper-checked,
.checkbox-group .ant-checkbox-wrapper-checked {
  color: white;
  background: #2C79F6!important;
}