
.theory-dialog {
  padding: 20px;
  background: white;
  border-radius: 10px;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.3) 0px 10px 30px 0px, rgba(0, 0, 0, .3) 0px 10px 10px 0px, rgba(10, 37, 64, 0.25) 0px -2px 6px 0px inset;
}
.theory-dialog__content {
  color: #4f558c;
  line-height: 1.2;
  font-size: 20px;
}
.theory-dialog__arrow {
  position: absolute;
  left: -16px;
  font-size: 30px;
  bottom: 10px;
  color: white;
}