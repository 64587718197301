.course-map {
  height: 100%;
}
.course-map .text-problem-task__container {
  overflow: auto;
}

.cat-final-test__map-bg {
  background: url("../images/cat-final-test-bg.png") no-repeat center;
  background-size: cover;
  background-color: black;
  width: 100%;
  height: 100%;
  position: relative;
  /*overflow: hidden;*/
  min-height: 100vh;
}

.cat-final-test__header {
  width: 100%;
  height: 11em;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 5em;
}
.cat-final-test__title {
  color: #37588D;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5em;
  margin: 0;
}

.cat-final-test__items-wrapper {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.cat-final-test__content-wrapper {
  width: 70em;
  transition: height .3s ease-in-out;
}
.cat-final-test__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5em;
}
.cat-final-test__item {
  border-radius: 1.1em;
  z-index: 2;
  transition: transform .3s ease-in-out;
  transform: scale(1);
  margin: 10px;
  width: 15em;
  background-color: #5371A5;
  height: 21em;
  padding: 1.4em;
  position: relative;
  overflow: hidden;
}
.cat-final-test__item-title {
  color: white;
  font-family: Roboto-Bold;
  font-size: 1.4em;
  text-align: center;
  /*padding-inline: 20px;*/
  line-height: 1.4em;
}
.cat-final-test__item-img {
  width: 8.5em;
  height: 8.5em;
  object-fit: contain;
  margin: .8em auto;
  display: block;
}
.cat-final-test__item-img--status {
  width: 4.2em;
  height: 4.2em;
  object-fit: contain;
  position: absolute;
  right: .8em;
  top: -.8em;
}
.cat-final-test__item:not([aria-disabled='true']) {
  cursor: pointer;
}
.cat-final-test__item:not([aria-disabled='true']):hover {
  transform: scale(1.05);
  z-index: 100;
}

.cat-final-test__placeholder {
  background-color: #5A75A4;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: .8;
}


.cat-final-test__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.4em 1.4em 0;
}
.cat-final-test__buttons button {
  margin: 1.4em;
}


.custom-button {
  color: white;
  border: 0;
  border-radius: 1em;
  padding: 1.6em 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.custom-button span {
  font-family: Roboto-Bold;
  font-size: 1.2em;
}

.timer {
  border-radius: 15px;
  padding: 10px 30px;
  color: white;
  background-color: #EF940C;
  font-family: Roboto-Bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.custom-button.custom-button--orange:focus,
.custom-button--orange {
  color: white;
  background-color: #FBA806;
  border-bottom: 5px solid #EF940C;
  width: 30em;
}
.custom-button--orange.custom-button--disabled:focus,
.custom-button--orange.custom-button--disabled {
  color: white;
  background-color: #FDDEA3;
  border-bottom: 5px solid #FABF67;
  cursor: default;
}
.custom-button.custom-button--orange:hover {
  color: white;
  background-color: #EF940C;
  border-bottom: 5px solid #EF940C;
}
.custom-button--orange.custom-button--disabled:hover {
  color: white;
  background-color: #FDDEA3;
  border-bottom: 5px solid #FABF67;
}
.custom-button.custom-button--purple:focus,
.custom-button--purple {
  color: white;
  background-color: #656BA7;
  border-bottom: 5px solid #40437D;
}

.custom-button.custom-button--purple:hover {
  color: white;
  background-color: #40437D;
  border-bottom: 5px solid #40437D;
}


.cat-final-test__results {
  margin-top: 1.5em;
  display: flex;
}

.cat-final-test__move-to-cat-map {
  /*width: 28.5em;*/
  margin: 0 1.4em;
}



@media screen and (max-width: 1500px) {
  .cat-final-test {
    font-size: .9em;
  }
}

@media screen and (max-width: 1200px) {
  .cat-final-test {
    font-size: .8em;
  }
}

@media screen and (max-width: 1100px) {
  .cat-final-test {
    font-size: .75em;
  }
}


@media screen and (max-width: 1000px) {
  .cat-final-test__items-wrapper {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .cat-final-test__items {
    margin-top: 17.5em;
  }
  .cat-final-test__results {
    align-items: center;
    margin-top: 1.2em;
    position: absolute;
    top: 0;
  }
  .map-progress-bar--horizontal {
    display: flex!important;
    margin-top: 20px;
    flex-direction: row-reverse!important;
  }
  .map-progress-bar__wrapper {
    max-height: 80px;
    display: flex;
    align-items: center;
  }
  .map-progress-bar--vertical {
    display: none!important;
  }
  .map-clock {
    height: 13em;
  }
  .map-clock__time {
    font-size: 2.4em!important;
  }
  .custom-button {
    width: 100%;
    margin: 1.3em 0;

  }
}

@media screen and (max-width: 750px) {
  .cat-final-test__content-wrapper {
    width: 100%;
  }
  .cat-final-test__items {
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .cat-final-test {
    font-size: .6em;
  }
}
@media screen and (max-width: 500px) {
  .cat-final-test {
    font-size: .5em;
  }
  .cat-final-test__item {
    font-size: 1.2em;
    margin: 5px;
  }
  .cat-final-test__buttons {
    flex-direction: column-reverse;
  }
  .cat-final-test__buttons .custom-button {
    margin: .5em 1.5em;
  }
}

@media screen and (max-width: 400px) {
  .cat-final-test {
    font-size: .4em;
  }
  .map-progress-bar {
    padding: 3px!important;
  }
}
