

.recommendation-block {}

.recommendation-block__btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 15px;
  border-radius: 50%;
  background: rgba(0,0,0,0);
  cursor: pointer;
  transition: background ease-in .3s;
}
.recommendation-block__btn:hover {
  background: rgba(0,0,0,0.2);
}
.recommendation-block__btn img {
  width: 100%;
  height: 100%;
}

.recommendation-block__content-wrapper {
  background: rgba(23,23,23,0.85);
  padding: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 0;
  bottom: -400px;
  margin: 0;
  transition: bottom ease-in-out .4s;
  overflow: hidden;
}
.recommendation-block__content-wrapper--visible {
  bottom: 0;
  height: 350px;
  padding: 50px 0 10px;
  margin: 10px 0 0!important;
  overflow: visible;
}
.recommendation-block__content-helper {
  overflow: auto;
  max-width: 100%;
  display: flex;
  padding: 10px 0 20px;
  opacity: 0;
  transition: opacity ease-in .4s;
}
.recommendation-block__content-helper--visible {
  opacity: 1;
}
.recommendation-block__course {
  max-width: 350px;
  display: block;
  flex-shrink: 0;
  margin: 0 3%;
  border: 2px solid;
  border-color: transparent;
  transition: border-color ease-in-out .3s, box-shadow ease-in-out .4s;
  border-radius: 10px;
  overflow: hidden;
}
.recommendation-block__course:hover {
  box-shadow: 0 2px 10px 2px rgba(255, 255, 255, .2);
  border-color: rgba(255,255,255,.5);
}
.recommendation-block__course img {
  width: 100%;
  height: 100%;
}

.recommendation-block__close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 30px;
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 850px) {
  .recommendation-block__course {
    max-width: 250px;
  }
  .recommendation-block__content-wrapper--visible {
    height: 270px;
  }
  .recommendation-block__btn {
    right: 10px;
    bottom: 10px;
  }
}
