

.theory-reset-btn:focus,
.theory-reset-btn {
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 17px;
  background: #F6692C;
  border-color: #F6692C;
}

.theory-reset-btn:hover {
  background: #fa8451;
  border-color: #fa8451;
}