
.statistic-modal {
  background: rgba(255,255,255, .5);
}
.statistic-modal__table {
  background: white;
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  margin: auto;
  overflow: hidden;
  width: 90%;
  height: 90%;
  border-radius: 30px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
.statistic-modal__table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;
  margin-bottom: 10px;
}
.statistic-modal__table-steps-btns {
  display: flex;
  align-items: center;
  padding: 20px 20px 0;
}
.statistic-modal__table-steps-btns .available-btn {
  margin-right: 10px;
  border-radius: 5px;
}
.statistic-modal__table-steps-btns .available-btn.available-btn--current {
  box-shadow: 0 0 2px rgba(255, 255, 255, 1) inset, 0 0 3px 1px rgba(36, 0, 255, 0.8);
}
/*.statistic-modal__table-steps-btns .available-btn--success:hover,*/
/*.statistic-modal__table-steps-btns .available-btn--success {*/
/*  */
/*}*/
.statistic-modal__table-steps-btns .available-btn--success:hover,
.statistic-modal__table-steps-btns .available-btn--success {
  background: #7cb305;
  color: white;
}

.statistic-modal__confirm .ant-popover-inner {
  border-radius: 10px!important;
}
.statistic-modal__confirm .ant-popover-message-title {
  font-family: Roboto;
  font-size: 17px;
  padding-left: 30px;
}
.statistic-modal__confirm .anticon {
  font-size: 17px;
}
.statistic-modal__confirm button {
  height: auto;
  font-size: 18px;
  border-radius: 5px;
  width: 60px;
}
.statistic-modal__confirm button span {
  font-family: Roboto-Medium;
}

.statistic-modal__update-table {
  border-radius: 10px;
}
.statistic-modal__update-table span {
  font-family: Roboto-Bold;
}
