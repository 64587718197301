
.vk_share_button {
  transform: scale(1.25);
  font-size: 30px;
  margin: 0;
  padding: 5px;
  display: inline-flex;
}

.vkShare__arrow {
  position: absolute;
  left: 50px; top: 0;
  bottom: 0;
  margin: auto;
}
.vkShare__arrow--animation {
  animation: move 1.2s infinite;
  animation-timing-function: linear;
}

@keyframes move {
  0% {
    left: 50px;
  }
  15%  {
    left: 60px;
  }
  25%  {
    left: 65px;
  }
  50% {
    left: 70px;
  }
  75%  {
    left: 65px;
  }
  85%  {
    left: 60px;
  }
  100% {
    left: 50px;
  }
}

