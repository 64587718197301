

.level-bar {
  flex-grow: 2;
  margin-left: 2.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.level-bar__progress-container {
  width: 100%;
}
.level-bar__progress-bg {
  background: #FCFCFC;
  border: .5em solid #304886;
  box-sizing: border-box;
  border-radius: 1.4em;
  width: 100%;
  height: 5em;
}
.level-bar__progress-border {
  width: 100%;
  height: 100%;
  border: .3em solid white;
  border-radius: 1em;
  overflow: hidden;
}
.level-bar__progress-value-line {
  background: #4461AB;
  height: 100%;
  width: 0;
  transition: width 1.5s ease-in-out;
}


.level-bar__new-points {
  top: 0;
  position: absolute;
  color: white;
  font-size: 5em;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  padding: 0 .3em;
  background: #E48E33;
  border-radius: .5em;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
.level-bar__new-points:before {
  content: '+';
}
.level-bar__points-wrapper {
  position: relative;
  width: 100%;
}
.level-bar__current-points {
  left: 0;
  top: 5px;
  position: absolute;
  background: #1C3169;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  padding: 0 0.3em;
  color: white;
  border-radius: 0.4em;
  transition: left 1.5s ease-in-out;
  margin-left: -3em;
}
.level-bar__max-points {
  position: absolute;
  background: #FFFFFF;
  border-radius: 0.6em;
  color: #304886;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8em;
  padding: 0.2em 0.6em;
  top: 10px;
  right: 0;
  margin-right: -0.8em;
}
