

.homework__menu,
.homework__menu div {
  display: flex;
  align-items: center;
}
.homework__menu {
  padding: 0 40px;
  max-width: 900px;
  justify-content: space-between;
}


.homework .studentsWrapper {
  flex-shrink: 0;
}
.homework .studentsWrapper p {
  white-space: nowrap;
  padding: 10px 20px 10px 0;
}

.homework .taskListWrapper {
  padding-top: 90px!important;
}

.hw__title {
  margin-right: 20px;
}

.hw__title h2 {
  cursor: default;
  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  font-family: 'Roboto-Bold';
}

.hw__removeBtn,
.hw__renameBtn {
  color: gray;
}

.createHwSpan,
.homework__menu button span {
  cursor: pointer;
  text-decoration: underline;
}
.createHwSpan:hover,
.homework__menu button:hover span {
  text-decoration: none;
}

.copyLinkBtn {
  width: 100%;
  padding: 30px 0 20px 40px;
}
.copyLinkBtn button {
  font-weight: bold!important;
  font-size: 16px;
}

.saveHwTitle span {
  text-decoration: none!important;
}

.hw__updateBtn {
  display: flex;
  align-items: center;
}
.hw__updateBtn span {
  text-decoration: none!important;
}



.lesson-item-container.students-complete-registr .lsnMainContainer {
  padding: 10px 0!important;
  display: flex!important;
  flex-direction: column!important;
  align-items: center!important;
  justify-content: space-around!important;
}
.lesson-item-container.students-complete-registr .lsnMainContainer h3 {
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.lesson-item-container.students-complete-registr .lsnMainContainer .ant-form-item-explain.ant-form-item-explain-error {
  font-size: 13px;
}
@media screen and (max-width: 600px) {
  .lesson-item-container.students-complete-registr .lsnMainContainer {
    padding: 20px 0!important;
  }
  .lesson-item-container.students-complete-registr .lsnMainContainer h3 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 350px) {
  .lesson-item-container.students-complete-registr .lsnMainContainer form {
    max-width: 200px!important;
  }
}




.lessonsContainer {
  padding: 20px 40px 40px;
  display: flex;
  overflow-y: auto;
}

/* Общий */
.lsnHwItemBg {
  background: rgb(218 234 249);
  /*padding: 0 20px;*/
}
.lsnHwItemDivider {
  min-width: 20px!important;
  max-width: 20px!important;
}
.table-student-txt {
  padding: 10px 20px 10px 0;
  width: 150px;
}






.lsnHwItemWrapper {
  margin-right: 20px;
  background: rgb(218 234 249);
  padding: 20px;
}
.lsnHwItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lsnHwItem>div {
  display: flex;
  align-items: center;
}
.lsnHwItem h3 {
  margin: 0 25px 0 0;
  font-weight: bold;
  font-size: 20px;
  max-width: 300px;
}
.lsnThumbImg {
  flex-shrink: 0;
  width: 50px;
  margin-right: 20px;
  height: 50px;
  object-fit: contain;
}

.removeBtn {
  height: 30px;
  width: 30px;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}
.removeBtn::before {
  content: '';
  width: 20px;
  height: 6px;
  position: absolute;
  background: whitesmoke;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
}

.hwNewItem {
  font-size: 16px;
  text-decoration: underline;
  width: 100%;
  line-height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  cursor: pointer;
}
.hwNewItem:hover {
  text-decoration: none;
}

.hwItem {
  width: 100%;
  line-height: 20px;
  font-weight: 300;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  display: block;
}
.hwItem h3 {margin: 0;}
.hwItemSelected {

}



.hw-table th {
  vertical-align: top;
}
.hw-table th span {
  display: block;
  color: #5d8bbd;
  font-weight: normal;
  max-width: 150px;
  line-height: 1;
  margin: 0 10px;
  padding: 10px 0;
}

.hw-table th:first-child span{
  margin-left: 0;
}
.hw-table tr td:first-child{
  color: #5d8bbd;
  font-weight: bold;
}


.taskResult {
  border: 1px solid rgba(180,180,180,1);
  width: 20px;
  height: 20px;
  margin: 10px auto;
  background: snow;
  border-radius: 50%;
}
.taskResult.tskTableSuccess{
  background: #7cefaa;
}
.taskResult.tskTableFail{
  background: #ffe681;
}






.emptyCompWrapp {
  width: 100%;
}
.emptyComp {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
.emptyIcon {
  opacity: .2;
  position: absolute;
  font-size: 10pc;
}



.homework .sectionWrapper {
  padding: 0 60px 20px;
  max-width: 1500px;
}
.teacher-content-wrapper {
  width: 81%;
}
@media screen and (max-width: 1200px) {
  .homework .contentWrapper {
    display: block;
  }
  .homework .teacher-content-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .homework .taskListWrapper {
    padding-inline: 0;
  }
  .homework__menu {
    padding: 0 20px;
  }
  .homework__menu div {
    flex-direction: column;
    align-items: flex-start;
  }
  .hw__title {
    margin-bottom: 10px;
  }
  .homework__menu .hw__removeBtn,
  .homework__menu .hw__renameBtn {
    margin: 10px 0 5px;
    border: 1px solid rgba(0,0,0,0.1);
  }
  .homework .lessonsContainer {
    padding-inline: 20px;
  }
  .homework .copyLinkBtn {
    padding-top: 10px;
    padding-inline: 5px;
  }
  .homework .sectionWrapper {
    padding: 0 20px 20px;
  }
  .homework .sectionWrapper h3 {
    font-size: 20px!important;
  }
}
@media screen and (max-width: 500px) {
  .hw__title h2 {
    font-size: 17px;
  }
}
