.map-clock {
  background: url("../images/clock.png") 50% 50% no-repeat;
  width: 13em;
  height: 16em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2em;
  background-size: contain;
  left: 0;
  top: 0;
}

.map-clock__time {
  font-family: Roboto-Bold;
  font-size: 2.5em;
  line-height: 2.8em;
  text-align: center;
  color: #37588D;
}
.map-clock__time-wrapper {
  margin-top: 2.5em;
}
