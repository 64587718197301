
.choose-item-task {

}
.choose-item-task__btn-continue {
  margin: auto!important;
  width: 30em;
}
.choose-item-task__items {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}
.choose-item-task__item {
  margin: 10px;
  cursor: pointer;
  border: 3px solid transparent;
}
.choose-item-task__item:hover {

}
.choose-item-task__item--hover:hover {
  border-color: #3E5193;
}
