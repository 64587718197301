
.news-feed__container {
  background-color: white;
  /*transition: background-color ease-in .4s;*/
}
.news-feed__wrapper {
  width: 100%;
  flex: 2;
}

.notifications {
  padding: 48px 70px 48px 25px;
  display: flex;
  overflow: scroll;
}

@media screen and (max-width: 1200px) {
  .notifications {
    padding: 48px 0 48px 25px;
  }
}

.notifications__item {
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.25);
  position: relative;
  width: 300px;
  height: 140px;
  background-color: white;
  border: 1px solid #FF9737;
  margin: 0 40px 0 0;
  padding: 15px 24px;
  flex-shrink: 0;
  cursor: pointer;
}
.notifications__item--create-new {
  width: 200px;
  height: 140px;
  padding: 20px;
  background-color: #7cd85b;
  display: flex;
  align-items: flex-end;
  border: none;
}
.notifications__item--create-new .title {
  color: white;
  font-family: Roboto-Bold;
  font-size: 17px;
  line-height: 1.2;
}
.notifications__item--create-new .plus-icon {
  color: white;
  font-size: 72px;
  position: absolute;
  top: -20px;
  right: 15px;
  font-family: Roboto-Black;
}


.notifications__lsn-classroom {
  font-size: 18px;
  font-family: Roboto-Bold;
  padding-right: 10px;
}
.notifications__lsn-name {
  font-size: 18px;
  line-height: 1.2;
}
.notifications__circle {
  width: 12px;
  height: 12px;
  background-color: #FF9737;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
}

.news-feed__content {
  padding: 0 25px;
  max-width: 1000px;
  width: 100%;
}
.news-feed__news-items {

}
.news-feed__news-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 30px 60px;
  background: #FFFFFF;
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.25);
  margin: 50px 0;
  min-height: 130px;
}


@media screen and (max-width: 1200px) {
  .news-feed__news-item {
    padding: 20px 30px;
  }
}


/* DARK MODE */

.app__main-container--dark-mode .news-feed__container {
  background-color: #234171;
}

.app__main-container--dark-mode .notifications__item {
  background: #234171;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.25);
}

.student-content-bg-img {
  position: absolute;
  right: 0;
  top: 20%;
  z-index: 0;
}
@media screen and (max-width: 800px) {
  .notifications {
    margin-bottom: 50px;
  }

  .student-content-bg-img {
    width: 200px;
  }
  .news-section-container {
    padding: 0;
  }
  .news-feed__wrapper .news-section-container .news-section-container__head {
    padding-inline: 30px;
  }
}
