


@font-face {
  font-family: 'Roboto-Black';
  src: url('./Fonts/Roboto/Roboto-Black.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-Black.eot'),*/
  /*url('./Fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-Black.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-Black.svg') format('svg');*/
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('./Fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-BlackItalic.eot'),*/
  /*url('./Fonts/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-BlackItalic.svg') format('svg');*/
  font-weight: 900;
  font-style: italic;
}



@font-face {
  font-family: 'Roboto-Bold';
  src: url('./Fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-Bold.eot'),*/
  /*url('./Fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-Bold.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-Bold.svg') format('svg');*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('./Fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-BoldItalic.eot'),*/
  /*url('./Fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-BoldItalic.svg') format('svg');*/
  font-weight: 700;
  font-style: italic;
}



@font-face {
  font-family: 'Roboto-Medium';
  src: url('./Fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-Medium.eot'),*/
  /*url('./Fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-Medium.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-Medium.svg') format('svg');*/
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('./Fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-MediumItalic.eot'),*/
  /*url('./Fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-MediumItalic.svg') format('svg');*/
  font-weight: 500;
  font-style: italic;
}



@font-face {
  font-family: 'Roboto';
  src: url('./Fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-Regular.eot'),*/
  /*url('./Fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-Regular.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-Regular.svg') format('svg');*/
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url('./Fonts/Roboto/Roboto-RegularItalic.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-RegularItalic.eot'),*/
  /*url('./Fonts/Roboto/Roboto-RegularItalic.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-RegularItalic.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-RegularItalic.svg') format('svg');*/
  font-weight: 400;
  font-style: italic;
}



@font-face {
  font-family: 'Roboto-Light';
  src: url('./Fonts/Roboto/Roboto-Light.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-Light.eot'),*/
  /*url('./Fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-Light.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-Light.svg') format('svg');*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('./Fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-LightItalic.eot'),*/
  /*url('./Fonts/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-LightItalic.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-LightItalic.svg') format('svg');*/
  font-weight: 300;
  font-style: italic;
}



@font-face {
  font-family: 'Roboto-Thin';
  src: url('./Fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-ThinItalic.eot'),*/
  /*url('./Fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-ThinItalic.svg') format('svg');*/
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('./Fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  /*url('./Fonts/Roboto/Roboto-ThinItalic.eot'),*/
  /*url('./Fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),*/
  /*url('./Fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),*/
  /*url('./Fonts/Roboto/Roboto-ThinItalic.svg') format('svg');*/
  font-weight: 100;
  font-style: italic;
}