

.custom-button {
  color: white;
  border: 0;
  border-radius: 1em;
  padding: 1.6em 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 3px rgb(255 255 255);
}
.custom-button span {
  font-family: Roboto-Bold;
  font-size: 1.2em;
}

.custom-button.custom-button--orange:focus,
.custom-button--orange {
  color: white;
  background-color: #FBA806;
  border-bottom: 5px solid #EF940C;
  width: 30em;
}
.custom-button--orange.custom-button--disabled:focus,
.custom-button--orange.custom-button--disabled {
  color: white;
  background-color: #FDDEA3;
  border-bottom: 5px solid #FABF67;
  cursor: default;
}
.custom-button.custom-button--orange:hover {
  color: white;
  background-color: #EF940C;
  border-bottom: 5px solid #EF940C;
}
.custom-button--orange.custom-button--disabled:hover {
  color: white;
  background-color: #FDDEA3;
  border-bottom: 5px solid #FABF67;
}

.custom-button.custom-button--purple:focus,
.custom-button--purple {
  color: white;
  background-color: #656BA7;
  border-bottom: 5px solid #40437D;
}

.custom-button.custom-button--purple:hover {
  color: white;
  background-color: #40437D;
  border-bottom: 5px solid #40437D;
}



.custom-button.custom-button--green:focus,
.custom-button.custom-button--green {
  color: white;
  background-color: #207E18;
  border-bottom: 5px solid #145F25;
}
.custom-button.custom-button--green:hover {
  color: white;
  background-color: #145F25;
  border-bottom: 5px solid #145F25;
}

.custom-button.custom-button--red:focus,
.custom-button.custom-button--red {
  color: white;
  background-color: #D83636;
  border-bottom: 5px solid #BB3737;
}
.custom-button.custom-button--red:hover {
  color: white;
  background-color: #BB3737;
  border-bottom: 5px solid #BB3737;
}
