
.theory-v2 {
  position: absolute;
  left: -10000px;
  bottom: 0;
  margin: auto;
  z-index: 200;
  opacity: 0;
  transition: opacity .8s;
}
.theory-v2--visible {
  left: 0;
  opacity: 1;
}

.theory-v2__bg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.theory-v2__page-wrapper {
  position: absolute;
  left: 130px;
  top: 70px;
  display: flex;
  align-items: center;
  height: 400px;
  max-width: 700px;
}

.theory-v2__page-left {
  height: 100%;
  width: 700px;
  max-width: 350px;
  margin: 0 10px 0 -10px;
  position: relative;
  padding: 20px;
  background-size: 98%;
  background-repeat: no-repeat;
  background-position: center;
}
.theory-v2__page-left-content {
  width: 100%;
  height: 100%;
}


.theory-v2__page-right {
  height: 100%;
  width: 700px;
  max-width: 350px;
  margin: 0 -10px 0 10px;
  position: relative;
  padding: 20px;
  background-size: 102%;
  background-repeat: no-repeat;
  background-position: center;
}
.theory-v2__page-right-content {
  width: 100%;
  height: 100%;
}



.theory-v2__content-wrapper {
  overflow: auto;
  height: 100%;
}


