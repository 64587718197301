
.continue-row-task {

}
.continue-row-task__btn-continue {
  margin: auto!important;
  width: 30em;
}
.continue-row-task__items {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.continue-row-task__drag-items-container {
  display: inline-flex;
  background-color: rgba(255,255,255, .5);
  border-radius: 2em;
  padding: .7em;
  height: 20em;
}
.continue-row-task__drag-items {
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-row-task__drag-item {
  cursor: grab;
  transform: translate(0, 0);
}

.continue-row-task__item {
  margin: .7em;
  border: .2em solid transparent;
}
.continue-row-task__item--added {
  position: absolute;
  left: -1.4em; top: -1.4em;
}
.continue-row-task__drop-item {
  background: rgba(255, 255, 255, 0.6);
  border: 1.4em solid #FFFFFF;
  border-radius: 2em;
  min-width: 25em;
  height: 25em;
  margin-left: 2em;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

.continue-row-task__drop-item--leave {
  background: rgba(255, 255, 255, 0.6);
}
.continue-row-task__drop-item--enter {
  background: rgba(255, 255, 155, 0.6);
}

.continue-row-task__item:hover {

}

.continue-row-task__item--hover:hover {
  border-color: #3E5193;
}
