

@media screen and (max-width: 600px) {
  .lesson-tag__content {
    padding: 0 8px!important;
  }
  .lesson-tag__title {
    font-size: 9px!important;
  }
}
