.farm-map-auth {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.farm-map-auth__bg {
  background: url("../images/farm-map-bg.png") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(10px);
}
.farm-map-auth::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, .5);
  z-index: 2;
}

.farm-map-auth__modal {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: white;
  border-radius: 20px;
  width: clamp(300px, 25em, 400px);
  height: clamp(150px, 25em, 200px);
  margin: 20px;
  box-shadow: 0 0 3px rgba(0,0,0,0.5);
}
.farm-map-auth__modal h2 {
  text-align: center;
  font-family: Roboto-Bold;
}
.farm-map-auth__modal input {
  margin: 10px 0 20px;
  height: 40px;
  border-radius: 5px;
  font-family: Roboto-Bold;
}
.farm-map-auth__modal .ant-btn {
  border-radius: 5px;
}

