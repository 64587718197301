.farm-map-background {
  background-image: url("../images/farm-map-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.farm-map2-background {
  background: url("../images/farm-map2-bg.png") no-repeat center;
  background-size: contain;
  background-color: black;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}




.farm-map-item {
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  transition: transform .3s ease-in-out, border-color .3s ease-in-out;
  transform: scale(1);
  width: 300px;
  height: 300px;
}
.farm-map-item--teacher:not(.farm-map-item--rounded) {
  border: 0!important;
}
.farm-map-item--teacher:hover {
  border: 10px solid #C6D346!important;
}
.farm-map-item--teacher.farm-map-item--active {
  transition: none!important;
  animation: none!important;
}

.farm-map-item--number {
  font-size: 40px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 100px;
  min-width: 70px;
  height: 70px;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  left: -15px;
  top: -15px;
}
.farm-map-item--success-img {
  position: absolute;
  width: 4.2em;
  height: 4.2em;
  object-fit: contain;
  right: 1.8em;
  top: 1.8em;
}
.farm-map-item--success {
  cursor: pointer;
}

.farm-map-item--active:not(:hover) {
  border: 10px solid rgba(255,255,255, 1);
  cursor: pointer;
  animation: pulsation 2s infinite;
  animation-timing-function: linear;
}
.farm-map-item--greece.farm-map-item--active {
  background-color: rgba(137, 89, 58, 0.35);
}
.farm-map-item--active:hover {
  border: 10px solid #C6D346;
  cursor: pointer;
}
.farm-map-item--disabled {

}


@keyframes pulsation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(.8);
    opacity: .3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

