
.school-task-card {
  background-color: white;
  border-radius: 2.1em;
  display: flex;
  flex-direction: column;
  width: 25em;
  min-height: 25em;
  padding: 1.4em;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}
.school-task-card--dragging {
  z-index: 100;
}
.school-task-card--draggable {
  cursor: grab;
  transform: translate(0, 0);
}

.school-task-card--small-size {
  width: 17.8em;
  min-height: 17.8em;
}
.school-task-card--hidden {
  visibility: hidden;
  overflow: hidden;
}
.school-task-card__answer {
  position: absolute;
  left: 1.5%; top: 1.5%;
  width: 97%;
  height: 97%;
  border-radius: 2.1em;
}
.school-task-card__answer--success {
  background-color: rgba(3, 167, 0, 0.51);
  border: 1em solid #0B9108;
}
.school-task-card__answer--fail {
  background: rgba(216, 54, 54, 0.49);
  border: 1em solid #D83636;
}


.school-task-card__success-icon {
  position: absolute;
  top: -1.4em;
  right: -0.7em;
  width: 7em;
  height: 7em;
  object-fit: contain;
}
.school-task-card__img {
  object-fit: contain;
  width: 100%;
  margin: auto;
}
.school-task-card__main-text {
  /*padding: 1.4em 0.7em 0;*/
  font-family: 'Roboto-Bold';
  font-size: 2em;
  text-align: center;
  color: #27397B;
  width: 100%;
}
.school-task-card__main-text-container {
  height: 22em;
  display: flex;
  align-items: center;
}
.school-task-card__main-text-container--small-size {
  height: 14em;
}
.school-task-card__text {
  padding: 1.4em 0.7em 0;
  font-family: 'Roboto-Bold';
  font-size: 1.4em;
  text-align: center;
}

.school-task-card__input {
  padding: 1.4em 0.7em 0;
  font-family: 'Roboto-Bold';
  font-size: 1.4em;
  text-align: center;
}

@media screen and (max-width: 1600px) {

}
