

.theory-comp__container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.theory-comp__background {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}

.theory-projector {
  background: whitesmoke;
  width: 65%;
  height: 95%;
  padding: 30px 40px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0 0 20px 20px;
  transition: transform 2s;
  transform: translateY(-800px);
}
.theory-projector--open {
  transform: translateY(0px);
}

.theory-projector__top-img {
  position: absolute;
  top: -10px;
  left: 0; right: 0;
  margin: auto;
  width: 700px;
  z-index: 2;
}
.theory-projector__content {
  overflow: hidden;
}


.theory-questions {
  overflow-y: auto;
  max-height: 430px;
}
.theory-questions__top-shadow {
  top: 25px;
  left: 0;
  transform: scaleY(-1);
  position: absolute;
}
.theory-questions__bottom-shadow {
  position: absolute;
  bottom: 72px;
  left: 0;
  right: 0;
  margin: auto;
  width: 95%;
}



.theory-projector__question {
  margin: 20px 0;
}
.theory-projector__question-title {
  margin: 0;
  color: #4f558c;
  font-family: Roboto;
  line-height: 1.3;
  opacity: 1;
}
.theory-projector__question-title p {
  font-size: 22px;
}

.theory-projector__question-title:hover {
  opacity: .7;
  cursor: pointer;
}



.theory__btn:focus,
.theory__btn {
  color: #4f558c;
  border-color: #4f558c;
  border-radius: 5px;
  opacity: 1;
}
.theory__btn:hover {
  color: #4f558c;
  border-color: #4f558c;
  opacity: .8;
}
.theory__btn span{
  font-family: Roboto-Bold;
}
.theory__btn--primary:focus,
.theory__btn--primary {
  color: white;
  border-color: #4f558c;
  background: #4f558c;
  opacity: 1;
}
.theory__btn--primary:hover {
  color: white;
  background: #4f558c;
  border-color: #4f558c;
  opacity: .8;
}

.theory__btn--skip-theory {
  position: absolute;
  z-index: 7;
  right: 40px;
  bottom: 30px;
}


/* ======== QUESTION PAGE ======== */


.question-page {
  max-height: 425px;
  overflow: scroll;
}
.question-page__body {
  margin: 15px 0;
}
.question-page__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 20px 40px;
}

.question-page__top-shadow {
  position: absolute;
  top: 20px;
  left: 0;
  transform: scaleY(-1);
}
.question-page__bottom-shadow {
  position: absolute;
  bottom: 75px;
  left: 0;
  z-index: 5;
}


