.map-progress-bar {
  border-radius: 10px;
  border: 2px solid #6682AF;
  background-color: white;
  padding: 6px;
  /*width: 70px;*/
  width: 6.5em;
  transform: rotate(180deg);
  transition: height .3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-progress-bar--horizontal .map-progress-bar__item {
  margin-left: 0.7em;
}
.map-progress-bar--horizontal {
  display: none;
  align-items: flex-end;
  justify-content: flex-start;
}
.map-progress-bar__wrapper {

}

.map-progress-bar__item {
  background: #BEDE61;
  border-radius: .8em;
  width: 100%;
  margin-top: 0.7em;
}
.map-progress-bar__item:first-child {
  margin-top: 0;
}
