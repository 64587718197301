

.health-bar {
  position: absolute;
  z-index: 10;
  left: 3em;
  top: 10.5em;
  display: flex;
  background-color: white;
  padding: 0.7em;
  border-radius: 0.7em;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
}
.health-bar__item {
  width: 2.5em;
  height: 2.5em;
  margin: 0 0.35em;
  opacity: 1;
  transition: opacity 1s;
}
.health-bar__item--hidden {
  opacity: 0;
}

@media screen and (max-width: 1500px) {
  .health-bar {
    top: 11em;
  }
}
