
.header-popUpMenu {
  position: absolute;
  padding: 10px 0;
  right: 0;
  top: 50px;
  background-color: rgba(255,255,255,1);
  box-shadow: 0 0 5px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border-radius: 3px;
  z-index: 3;
}

.header-popUpMenu__item-wrapper .ant-badge-dot {
  right: auto;
  left: 4px;
}
.header-popUpMenu__item {
  cursor: pointer;
  min-width: 200px;
  padding: 7px 15px;
}
.header-popUpMenu__item:not(.header-popUpMenu__item--txt):hover {
  background: rgba(77, 111, 154, .05);
}
.header-popUpMenu__item--name {
  color: black!important;
  font-weight: bold;
  margin-bottom: 5px;
}

.header {
  height: 85px;
  z-index: 103;
  position: sticky;
  top: 0;
  /*transition: background-color ease-in .4s;*/
}
.header__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 20px;
  background-color: white;
  height: 86px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}
@media screen and (max-width: 600px) {
  .header__container {
    padding: 15px 10px;
  }
}

.header__menu-btn {
  margin-right: 10px;
  font-size: 18px;
  padding: 0 20px;
  height: 37px;
  display: none;
  align-items: center;
  position: absolute;
  bottom: -38px;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .header__menu-btn {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 500px) {
  .header__menu-btn {}
}


.header__logo {
  margin: 0 10px 5px;
  height: 40px;
}
@media screen and (max-width: 500px) {
  .header__logo {
    height: 30px;
    margin-bottom: 0;
  }
}

.header__left {
  width: 80%;
  display: flex;
  align-items: center;
}
.header__right {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 500px) {
  .header__left {
    width: 40%;
  }
  .header__right {
    width: 60%;
  }
}

.header__homeworkBtn {
  color: white;
  height: 40px;
  margin-right: 10px;
}

.feedbackBtn__text {}
.feedbackBtn__text--short {
  display: none;
}
.header__feedbackBtn {
  width: 280px;
  height: 40px;
  font-size: 17px;
  font-weight: 500;
  color: white;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  min-width: 150px;
}
@media screen and (max-width: 1200px) {
  .feedbackBtn__text {
    font-size: 15px;
  }
  .feedbackBtn__text {
    display: none;
  }
  .feedbackBtn__text--short {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .header__feedbackBtn {
    font-size: 13px;
  }
}



.header__popupMenuWrapper {
  position: relative;
  margin: 5px 0 0;
}
.header__userName {
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all .15s linear;
  text-decoration: underline;
  text-decoration-color: lightgray;
}
.header__userName > div{
  font-size: 17px;
  color: black;
  font-weight: normal;
}

.header__userName--hover:hover {
  background: rgba(153, 192, 214, .15)!important;
}
.header__userAvatar {
  margin: 0 15px 0 0;
  position: relative;
}


@media screen and (max-width: 1000px) {
  .header__popupMenuWrapper,
  .header__userName > div {
    font-size: 15px;
  }
  .header__userAvatar {
    top: 0;
  }
}

@media screen and (max-width: 600px) {
  .header-menu__item a,
  .header__popupMenuWrapper,
  .header__userName > div {
    font-size: 13px;
  }
}





.header-menu {
  display: flex;
  margin: 2px 0 0;
}
.header-menu__wrapper--adaptive {
  padding: 10px 20px;
  background: white;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.13);
  transition: top ease-in-out .5s, z-index 0s;
  top: -285px;
  z-index: -1;
  position: relative;
  width: 100%;
}
.header-menu__wrapper--adaptive.header-menu__wrapper--visible {
  transition: top ease-in-out .5s, z-index 0s .6s;
  top: 0;
  z-index: 2;
}

.header-menu__wrapper--adaptive .header-menu {
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.header-menu__wrapper--adaptive .header-menu li {
  padding: 10px 0;
}
.header-menu--vertical {
  flex-direction: column;
}
.header-menu__item {
  list-style: none;
  margin: 0;
}
.header-menu__item a {
  color: black;
  font-size: 14px;
  margin: 0 10px;
  text-decoration: underline;
  text-decoration-color: lightgray;
  white-space: nowrap;
}
.header-menu__item a:hover {
  color: #7ba9ff;
  text-decoration-color: #7ba9ff;
}
.header-menu__item--active a {
  color: #2C79F6;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #2C79F6;
}

.header__main-menu-btn {
  display: none;
}
@media screen and (max-width: 1200px) {
  .header__container .header-menu {
    display: none;
  }
  .header__main-menu-btn {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .header-menu__wrapper--adaptive .header-menu__item {
    display: flex;
    justify-content: center;
  }
  .header-menu__wrapper--adaptive .header-menu {
    display: block;
  }
}



/* DARK MODE */

.app__main-container--dark-mode .header__container {
  background-color: #1B3359;
}
.app__main-container--dark-mode .header .header-menu__wrapper--adaptive {
  background-color: #1b3359;
}
.app__main-container--dark-mode .header .header-menu__wrapper--adaptive .header-menu__item:not(.header-menu__item--active) a:not(:hover),
.app__main-container--dark-mode .header__container .header-menu__item:not(.header-menu__item--active) a:not(:hover) {
  color: white;
}
.app__main-container--dark-mode .header .header-menu__wrapper--adaptive  .header__userName span,
.app__main-container--dark-mode .header__container .header__userName span {
  color: white;
}
.app__main-container--dark-mode .header .header-menu__wrapper--adaptive .header__userName span:hover,
.app__main-container--dark-mode .header__container .header__userName span:hover {
  color: #7ba9ff;
  text-decoration-color: #7ba9ff;
}
