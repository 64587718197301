.shield {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /*width: 70px;*/
  width: 5em;
}
.shield--big {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shield--big svg {
  position: absolute;
  /*width: 70px;*/
  width: 5em;
}
.shield--small {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shield--small svg {
  position: absolute;
  /*width: 52px;*/
  width: 3.85em;
  margin-top: -1px;
}
.shield__text {
  position: relative;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  /*font-size: 40px;*/
  font-size: 3em;
  /*line-height: 90px;*/
  line-height: 2em;
  color: white;
  margin-top: -5px;
}
