
.result-bar {
  position: absolute;
  z-index: 10;
  right: 3em;
  top: 10.5em;
  display: flex;
  background-color: white;
  padding: 0.7em;
  border-radius: 0.7em;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
}
.result-bar__item {
  width: 2.5em;
  height: 2.5em;
  background-color: #f2f7f2;
  border: 0.2em solid #0B9108;
  margin: 0 0.35em;
  border-radius: 0.35em;
}
.result-bar__item--complete {
  background-color: rgba(3, 167, 0, 0.51);
}

@media screen and (max-width: 1500px) {
  .result-bar {
    top: 11em;
  }
}
