

.layout2 .taskListWrapper {
  background-color: transparent;
  width: 100%;
  padding: 25px;
  min-width: 600px;
  margin-top: -40px;
}
.layout2 .taskListWrapper .taskListWrapper__title {
  padding: 0 30px;
}
@media screen and (max-width: 1200px) {
  .layout2 .taskListWrapper {
    min-width: 450px;
  }
}
@media screen and (max-width: 800px) {
  .layout2 .taskListWrapper .taskListWrapper__title {
    padding: 0 20px;
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  .layout2 .taskListWrapper {
    min-width: 100%;
    padding: 10px 15px;
  }
}



.layout2 .descriptionWrapper {
  background-color: transparent;
  width: 100%;
  min-width: 400px;
  max-width: 550px;
  overflow: hidden;
  display: flex;
  position: fixed;
  padding-top: 85px;
  top: 0;
  right: 0;
  height: 100vh;
  transition: width .6s ease-in-out;
}

.layout2 .descriptionWrapper .enterToTaskBackground {
  width: 95%;
  left: auto;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  position: absolute;
  transition: right .6s ease-in-out, background .4s;
  background-color: rgba(255,255,255,0.5);
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.layout2 .descriptionWrapper .enterToTaskBtn {
  margin: 20px auto;
  display: block;
  transition: right .6s ease-in-out, background .4s, color .4s;
  font-size: 20px;
  height: auto;
  width: 85%;
  border: 1px solid #82C76A;
  background: #82C76A;
  color: white;
  padding: 10px 0;
}
.layout2 .descriptionWrapper .enterToTaskBtn:hover {
  background: #83d766;
  color: white;
}
.layout2 .descriptionWrapper .enterToTaskBtn .enterToTaskBtnIcon {
  color: white!important;
}

.layout2 .descriptionShowHideContainer {
  transition: opacity .8s ease-in-out, right .6s ease-in-out;
  position: fixed;
  height: 100%;
  max-width: 530px;
  box-shadow: 0 2px 20px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

.layout2 .descriptionShowHideContainer--visible {
  right: -10px;
  opacity: 1;
}
.layout2 .descriptionShowHideContainer--hidden {
  right: -600px;
  opacity: 0;
}

@media screen and (max-width: 1200px) {
  .layout2 .descriptionWrapper {
    min-width: auto;
  }
}
@media screen and (max-width: 600px) {
  .layout2 .descriptionWrapper .HtmlContentRender {
    font-size: 10px;
  }
  .layout2 .descriptionWrapper .enterToTaskBackground {
    width: 97%;
  }
}

.mainContentWrapper {
  flex: 1;
  background-color: #FFFFFF;
}
.contentWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.taskListWrapper__title,
.sectionWrapper h3 {
  font-weight: bold;
  font-size: 24px;
  margin: 0 10px 0 0;
  font-family: 'Roboto-Bold';
}


.teacher-catalog-item {
  min-height: 74px;
  color: #2C79F6;
  border-bottom: 1px solid rgba(44, 121, 246, .3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px 0 20px;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}
.teacher-catalog-item:hover {
  border-bottom-color: #2C79F6;
}



.lsnGroupTitle {
  font-weight: 700;
  margin: 35px 0;
  line-height: 22px;
  font-size: 18px;
  color: black;
}

.lsnTitle {
  font-weight: 400;
  color: black;
  font-size: 18px;
}
.lsnMainContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 0px 2px 1px rgba(255,255,255,0.4);
  border: 1px solid #D0D0D0;
}
.lsnDescription {
  font-size: 14px;
}
.lsnContainerLeft {
  width: 100%;
  display: flex;
  align-items: center;
}
.lsnThumbContainer {
  width: 10%;
  min-width: 75px;
}
.lsnThumbImg {
  width: 80%;
  height: auto;
  object-fit: contain;
  border-radius: 50%;
  background: #bebbbb;
}
.lsnContainerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.lsnPlayImgWrapper {}
.lsnPlayImg {}

.lsnAddTaskToHW {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  border: 1px solid rgba(255,255,255, .6);
  position: relative;
}

.lsnAddTaskToHW:not(.singleAdded)::after,
.lsnAddTaskToHW::before {
  content: '';
  width: 20px;
  height: 6px;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
}
.lsnAddTaskToHW::after {
  transform: rotate(90deg);
}
.lsnAddTaskToHW::before {}

@media screen and (max-width: 500px) {
  .lsnGroupTitle {
    font-size: 13px;
  }
  .lsnTitle {
    font-size: 12px;
  }
  .lsnDescription {
    font-size: 11px;
  }
  .lsnContainerLeft {
    width: 60%;
  }
  .lsnPlayImgWrapper {
    /*width: 40px;*/
    /*height: 40px;*/
  }
  .lsnPlayImg {
    width: 100%;
    height: 100%;
  }
  .lsnThumbContainer {
    width: 20%;
  }
  .lsnThumbImg {
    width: 100%;
  }
}

.menu-wrapper {
  min-width: 150px;
  box-shadow: rgba(0 0 0, .3) 0 4px 5px 3px;
}

.lsnItemPlayIcon {
  margin: auto;
  height: 20px;
  width: 15px;
}

.lsnItemPlayIconWrapper {
  display: flex;
  height: 45px;
  width: 45px;
  background-color: #82C76A;
  border: 1px solid #82C76A
}

.lsnItemEditIcon {
  margin: auto;
  height: 30px;
  width: 30px;
}

.lsnItemEditIconWrapper {
  display: flex;
  height: 45px;
  width: 45px;
  background-color: #FFFFFF;
  border: 1px solid #2F7DF6;
  margin: 0px 15px 0px 0px
}

.popup-for-feedback h2 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
}
.popup-for-feedback p {
  margin-bottom: 10px;
  font-size: 15px;
}
.popup-for-feedback span {

}

.popup-for-feedback form .ant-form-item {
  margin-bottom: 10px;
}
.popup-for-feedback form button {
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.25);
  height: auto;
  width: 150px;
  background-color: #5991EB;
  margin-top: 12px;
}
.popup-for-feedback form button span {
  font-size: 18px;
}



/* ----------------- NEW GRID ----------------- */

.lessons--new-grid .homeworks-tags {
  display: none!important;
}
.teacher-content-wrapper.lessons--new-grid .lessonsContainer {
  padding-inline: 40px;
}
.lessons--new-grid .lessonsContainer {
  padding-inline: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.lessons--new-grid .lessonsContainer .lesson-item-container {
  display: block;
  max-width: 335px;
  width: 47%;
  margin: 10px 0 25px!important;
}
.lessons--new-grid .lessonsContainer .lsnMainContainer {
  display: block;
  position: relative;
  height: 100%;
}
.lessons--new-grid .lessonsContainer .lsnThumbContainer {
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lessons--new-grid .lessonsContainer .lsnThumbImg {
  display: block;
  width: 100%;
  height: auto;
  min-height: 170px;
  max-height: 170px;
  border-radius: 0;
  background-color: white;
  /*object-fit: cover!important;*/
  margin: auto;
}
.lessons--new-grid .lessonsContainer .lsnContainerRight {
  position: absolute;
  top: 10px;
  right: 10px;
}
.lessons--new-grid .lessonsContainer .lsnContainerLeft {
  padding: 10px;
}
.lessons--new-grid .lsnGroupTitle {
  margin-bottom: 0;
}


@media screen and (max-width: 600px) {

  .lessons--new-grid .lsnGroupTitle {
    font-size: 17px;
  }
  .lessons--new-grid .lessonsContainer {
    justify-content: space-around;
  }
  .lessons--new-grid .lessonsContainer .lesson-item-container {
    width: 100%;
  }
  .lessons--new-grid .lessonsContainer .lsnContainerLeft {
    width: 100%;
  }
  .lessons--new-grid .lessonsContainer .lsnContainerLeft .lsnTitle {
    font-size: 17px;
  }
}
