
.main-page-sills {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -50px 150px;
  width: 100%;
  position: relative;
  max-width: 1000px;
}
.main-page-sills__item {
  background: #234171;
  border: 2px solid #76E342;
  box-sizing: border-box;
  box-shadow: 0 2px 20px 1px rgba(118, 227, 66, 0.5);
  max-width: 420px;
  width: 100%;
  min-height: 400px;
  padding: 12px 12px 40px;
  margin: 40px 50px;
}

.skill-item__img {
  width: 100%;
}
.skill-item__link {
  width: 100%;
  height: auto;
  padding: 10px!important;
  color: rgba(35, 65, 113, 1);
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.25);
}
.skill-item__text {
  margin-top: 25px;
  color: white;
  font-size: 18px;
}
.skill-item__text h4 {
  color: white;
  font-weight: bold;
}
.skill-item__text p {
  margin-top: 20px;
}


@media screen and (max-width: 1500px) {
  .main-page-sills {
    margin-inline: -30px;
  }
  .main-page-sills__item {
    margin: 30px;
  }
}

@media screen and (max-width: 766px) {
  .main-page-sills {
    margin: 30px 0 150px;
    justify-content: center;
  }
  .main-page-sills .skills-row {
    width: 100%;
  }
  .skill-item__text {
    font-size: 18px;
  }
  .main-page-sills__item {
    margin: 20px auto;
  }
}
@media screen and (max-width: 450px) {
  .main-page-sills__item {
    max-width: 300px;
  }
}
