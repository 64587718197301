

.infoWrapper {
  background: white;
  padding: 1px 30px 30px;
}
.blockContainer {

}
.itemsContainer {

}
.blockTitle {
  margin: 30px 0 10px;
  font-size: 25px;
  font-weight: bold;
}
.itemRow {
  line-height: 35px;
}
.infoIcon {
  font-weight: bold;
  font-size: 20px;
}
.infoTitle {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.5;
  margin: 10px;
}
.infoValue {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
  margin: 10px;
}