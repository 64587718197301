
.match-items {

}
.match-items__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.match-items__btn-continue {
  margin: auto!important;
  width: 30em;
}

.match-items__drop-items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.8em;
  height: 50%;
}

.match-items__drop-item {
  margin: 0 2.8em;
}


.match-items__drag-items-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.match-items__drag-items {
  display: inline-flex;
  align-items: center;
  background-color: rgba(255,255,255, .5);
  border-radius: 2em;
  padding: 0.7em;
  height: 22em;
}
.match-items__drag-item {
  margin: 0 1.7em;
}
