.header-banner-carousel {
  z-index: 105;
}
.header-banner-carousel .slick-dots li button {
  height: 5px;
}
.header-banner-carousel .slick-dots-bottom {
  bottom: 0;
}
.header-banner {
  height: 100px;
  display: block;
  position: relative;
}

.header-banner .header-banner__img {
  height: 100%;
  margin: 0 auto;
  display: block;
  object-fit: contain;
}

.header-banner .close-btn {
  cursor: pointer;
  width: 40px; height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255, .4);
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  font-size: 20px;
}
.header-banner .close-btn:hover {
  font-size: 23px;
}


.header-banner-carousel--mobile .slick-dots-bottom {
  display: none;
}
.header-banner-carousel--mobile .header-banner {
  height: 80px;
}
