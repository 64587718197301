
.health-modal__backdrop {
  background-color: rgba(255,255,255,.5);
  position: absolute;
  left: 0; top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: opacity 1s .2s, z-index 0s .5s;
}
.health-modal__backdrop--visible {
  z-index: 100;
  opacity: 1;
}
.health-modal {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 5px solid white;
  padding: 20px;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  background: url("../images/modalBg.png") no-repeat 50% 50%;
  background-size: cover;
  max-width: 300px;
  width: 100%;
}
.health-modal img {
  width: 130px;
  height: 130px;
}

.health-modal__button {
  margin: 20px auto 0!important;
  max-width: 25em!important;
  width: 100%!important;
}

.school-tasks__try-again__button {
  margin: 20px auto 0!important;
  max-width: 25em!important;
  width: 100%!important;
  bottom: 7.1em;
}

.health-modal__text {
  font-size: 50px;
  color: white;
  font-family: Roboto-Bold;
}

@media screen and (max-width: 1500px) {

}
