
.main-page-achievements {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -60px 100px;
  position: relative;
}
.main-page-achievements__item {
  max-width: 250px;
  margin-right: -30px;
  margin-top: -30px;
}
.achievement-item__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}



@media screen and (max-width: 800px) {
  .news-section-container {
    padding: 0;
  }
  .news-feed__wrapper .news-section-container .news-section-container__head {
    padding-inline: 30px;
  }
  .main-page-achievements {
    display: flex;
    flex-wrap: nowrap;
    /*width: 300px;*/
    margin: 0 0 100px;
    position: relative;
    overflow: auto;
  }
  .main-page-achievements__item {
    max-width: 250px;
    min-width: 250px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .achievement-item__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}
