
.main-menu-item-transition {
  display: flex;
}
.main-menu-item-transition-enter {
  opacity: 0;
}

.menu-list-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.main-menu-item-transition-leave {
  opacity: 1;
}

.menu-list-transition-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

