

.arrange-by-columns__columns {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 70px;
}

.arrange-by-columns__btn-check,
.arrange-by-columns__btn-continue {
  margin: auto!important;
  width: 30em!important;
}
.arrange-by-columns__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (max-width: 1000px) {

}
@media screen and (max-width: 450px) {
  .arrange-by-columns__btn-check,
  .arrange-by-columns__btn-continue {
    margin: auto !important;
    width: 90% !important;
  }
}
